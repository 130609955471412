export default (state, action) => {
    switch(action.type) {
    case 'SAVE_USER':
      return {
        ...state,
        loading: false,
        user: action.payload
      }
      case 'LANGUAGES':
        return {
          ...state,
        loading: false,
        languages: action.payload,
      }
      case 'CATEGORIES':
        return {
          ...state,
        loading: false,
        categories: action.payload,
      }
      case 'TAGS':
        return {
          ...state,
        loading: false,
        tags: action.payload,
      }
      case 'MANUFACTURERS':
        return {
          ...state,
        loading: false,
        manufacturers: action.payload,
      }
      case 'PRODUCT_FEATURES':
        return {
          ...state,
        loading: false,
        product_features: action.payload,
      }
      case 'PRODUCTS':
        return {
          ...state,
        loading: false,
        products: action.payload,
      }
      case 'SET_LOADING':
        return {
          ...state,
        loading: state.loading ? false : true
      }
      case 'SET_LISTVIEW':
        return {
          ...state,
          listview: state.listview ? false : true
      }
      case 'SET_CURRENTPAGE':
        return {
          ...state,
          currentPage: action.payload
      }
      default:
      throw new Error("Bad Action Type");
    }
  }
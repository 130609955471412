import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.on('languageChanged', function (lng) {
    
    // if the language we switched to is the default language we need to remove the /en from URL
   /* if (lng === i18n.options.fallbackLng[0]) {
      if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
        const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
        window.location.replace(newUrl)
      }
    }*/
})

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
        detection: {
            order: ['path','browser','localStorage'],
            lookupCookie: process.env.TITLE,
            lookupLocalStorage: process.env.TITLE,
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            // cache user language on
            //caches: ['localStorage'],
            cookieDomain: process.env.TITLE
        },
        debug: false,
        defaultNS: 'common',
        fallbackLng: 'pt',
        backend: {
            loadPath: process.env.PUBLIC_URL+'/locales/{{lng}}/{{ns}}.json',
            parse: JSON.parse,
           crossDomain: true,
           withCredentials: false,
        },
        //lng: 'pt',
        // Using simple hardcoded resources for simple example
        react: {
            wait: false,
            useSuspense: true
        } 
    })

export default i18n
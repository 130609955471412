import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalState";
import { useLocation  } from 'react-router-dom'

const Dropdown = () => {

  const location = useLocation();
  const { i18n } = useTranslation();
  const { languages } = useContext(GlobalContext);
  const [show, setFixed] = useState(false);

  const changeLanguage = (lang) => {

    const result = location.pathname.replace(i18n.language, lang);

    i18n.changeLanguage(lang);

    //se estamos nos produtos temos de fazer redirect para o root
    if (window.location.href.indexOf("produtos") > -1) {
        window.location.href = '/' + lang + '/produtos';
    } else {
        window.location.href = result;
    }

};

  const showMenu = () => {
    setFixed(!show ? true : false);
  };

  return (
    <div className={`dropdown  ${show ? "dropdown-show" : ""}`}>
      <div className="dropdown-btn cursor" onClick={showMenu}>
        <img src={require('../../img/languages/'+i18n.language+'.png')} width={'20px'} alt={i18n.language} />
      </div>
      <ul>
        {languages.map((lang) => (
          <li key={lang.id} onClick={() => changeLanguage(lang.iso_code)} className="cursor">    
              <img src={require('../../img/languages/'+lang.iso_code+'.png')} width={'20px'} alt={lang.name} /> {lang.name}        
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;

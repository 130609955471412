import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import { useTranslation } from "react-i18next";
import Image from "../Images/Img";

const Index = () => {

  const { manufacturers } = useContext(GlobalContext);

  const { i18n } = useTranslation();

  return (
    <div className="logos">
      {manufacturers.map((manufacturer,idx) => (
        
        /*<Link to={`/${i18n.language}/filter/brand/${manufacturer.id}`} key={manufacturer.id}>*/
          <Image
            key={idx}
            size={"Niara_medium"}
            type={"manufacturers"}
            cat={manufacturer.id}
            title={manufacturer.name}
            width={125}
            height={125}
            style={{ margin: "auto" }}
          />
         /* </Link>*/
       
      ))}
    </div>
  );
};

export default Index;

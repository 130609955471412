import React, {useEffect,useState } from 'react';
import { useLocation } from "react-router-dom";
import useBodyClass from '../BodyClass';
import Menu from "./Menu";
import Search from "./Search";

const menu = require(`../../img/icons/menu.svg`);
const close = require(`../../img/icons/close.svg`);

const Hamburger = () => {

    let location = useLocation();

    useEffect(() => {

      //fecha o menu se mudarmos de link
      toogleMenu()

    },[location])

    const [showMenu, toogleMenu] = useState(false);

    const handleChange = event => {
        toogleMenu( showMenu ? false : true);
    };

    //add open or close tag to body
    useBodyClass(showMenu ? 'modal-open':'modal-close');

    return (
        <div className={ ( showMenu ? "hamburger-menu-big ":"") + "hamburger-menu"}>

            <div className='hamburger-menu-btn' onClick={handleChange}>
                {showMenu ? <img src={close} alt="close" width="15" /> : <img src={menu} alt="menu" width="15" />}
            </div>

            {showMenu &&  <Search /> }
            {showMenu &&  <Menu /> }
           
        </div>
    );
};
    
export default Hamburger;


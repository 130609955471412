import axios from 'axios';
import promise from 'promise';
import i18next from 'i18next';

const queryString = window.location.pathname;
const lang = i18next.language;

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': lang || queryString.substring(1,3)
  }
};


/*
const tokenService = {
  getNewToken: function() {
    console.log('refreshing token')
    return false;
  }
}
*/

// Add a request interceptor 
var axiosInstance = axios.create(options);

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(function (response) {

  //console.log(response);
  return response;
  
},(error) => {
  
  //console.error(error.message);

   // Do something with request error 
  if (error.response.status === 400 || error.response.status === 402 ) {
   
    
  } else if (error.response.status === 401) {
    
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_USER);
    window.location = '/login';

  } else {

    // redirect to another react router page 
    throw new axios.Cancel(error.message);

  }

  return promise.reject(error.response.data || error.message);

  /*
  if (error.response.status === 402 || error.response.status === 403) {
    return promise.reject(error.response.data);
  }

  if (error.response.status !== 401) {
    
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location = '/login';

    return promise.reject(error.response.data);
  }

  // Try request again with new token
  return tokenService.getNewToken().then((token) => {

    // New request with new token
    const config = error.config;
    config.headers['Authorization'] = `Bearer ${token}`;

    return promise((resolve, reject) => {
      axios.request(config).then(response => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    });

  }).catch((error) => {
    promise.reject(error);
  });*/

});

export default axiosInstance;



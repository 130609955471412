import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import Hamburger from "./Hamburger";

const logo = require(`../../img/logo-small.png`);
const logoxs = require(`../../img/logo-xs.png`);

const Index = () => {

  const { i18n } = useTranslation();

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [stickHeader, setStickHeader] = useState(false);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  },[]);

  useEffect(() => {
    //console.log(scrollTop);
    if(scrollTop >= 140){
      setStickHeader(true);  
    } else {
      setStickHeader(false);  
    }
  }, [scrollTop])

  return (
    <div className={"header " + (stickHeader ? "stickHeader" : "")}>
      <div className="container padding-0">
        <div className="header-content">
          <Link to={`/${i18n.language}/`}>
            {stickHeader ? (<img
              alt={process.env.REACT_APP_TITLE}
              className="img-responsive"
              src={logoxs}
            />) : (<img
              alt={process.env.REACT_APP_TITLE}
              className="img-responsive"
              src={logo}
            />)}
            
          </Link>
          <Menu />
          <Hamburger />
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { createContext, useReducer } from "react";
import Api from "../api/api";
import AppReducer from "./AppReducer";

// Initial state
const initialState = {
  languages: [],
  products: [],
  categories: [],
  tags: [],
  manufacturers: [],
  product_features: [],
  loading: true,
  listview: true,
  currentPage: 0
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  
  const [state, dispatch] = useReducer(AppReducer, initialState);

  async function getLanguages() {
    try {
      const res = await Api.languages();

      dispatch({
        type: "LANGUAGES",
        payload: res.languages,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function getCategories() {
    try {
      const res = await Api.categories();

      dispatch({
        type: "CATEGORIES",
        payload: res.categories,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function getProducts() {
    try {
      const res = await Api.allProducts();

      dispatch({
        type: "PRODUCTS",
        payload: res,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function getTags() {
    try {
      const res = await Api.tags();

      dispatch({
        type: "TAGS",
        payload: res.tags,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function getProductFeatures() {
    try {
      const res = await Api.product_features();

      dispatch({
        type: "PRODUCT_FEATURES",
        payload: res.product_features,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async function getManufacturers() {
    try {
      const res = await Api.manufacturers();

      dispatch({
        type: "MANUFACTURERS",
        payload: res.manufacturers,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  // Actions
  async function cpeList(id) {
    try {
      
      const res = await Api.products(id);

      dispatch({
        type: "PRODUCTS",
        payload: res.products,
        loading: true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  function setLoading() {
    dispatch({
      type: "SET_LOADING",
      payload: state.loading,
    });
  }

  function setListView() {
    dispatch({
      type: "SET_LISTVIEW",
      payload: state.listview,
    });
  }

  function setCurrentPage(page) {
    dispatch({
      type: "SET_CURRENTPAGE",
      payload: page,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        languages: state.languages,
        products: state.products,
        categories: state.categories,
        tags: state.tags,
        manufacturers: state.manufacturers,
        loading: state.loading,
        listview: state.listview,
        product_features: state.product_features,
        currentPage: state.currentPage,
        getLanguages,
        getCategories,
        getProducts,
        getTags,
        getProductFeatures,
        getManufacturers,
        cpeList,
        setLoading,
        setListView,
        setCurrentPage
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

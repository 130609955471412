import React, { Suspense, useContext, useEffect } from "react";
import GlobalStyle from "./theme/globalStyle";
import { GlobalContext } from "../context/GlobalState";
import LoadingOverlay from "react-loading-overlay";
import { BreakpointProvider } from "react-socks";
import ReactNotifications from "react-notifications-component";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AuthProvider } from "./Auth/Index";
import i18next from 'i18next';
import Layout from "../layout/Index";
import PrivateRoute from "./Auth/PrivateRoute";

import "../styles/css/flexboxgrid.css";
import "../styles/scss/App.scss";

const Home = React.lazy(() => import("./Home/Index"));
const Quemsomos = React.lazy(() => import("./Quemsomos/Index"));
const Recursos = React.lazy(() => import("./Recursos/Index"));
const Conta = React.lazy(() => import("./Conta/Index"));
const Produtos = React.lazy(() => import("./Produtos/Index"));
const Produto = React.lazy(() => import("./Produto/Index"));
const Search = React.lazy(() => import("./Search/Index"));
const Filter = React.lazy(() => import("./Filter/Index"));
const Register = React.lazy(() => import("./Conta/Register"));
const Login = React.lazy(() => import("./login/Signin"));
const Recover = React.lazy(() => import("./login/Recover"));
const Privacy = React.lazy(() => import("./Privacy/Index"));
const News = React.lazy(() => import("./News/Index"));

const App = () => {

  const { getLanguages, getCategories, getProductFeatures, getManufacturers, getProducts } = useContext(GlobalContext);

  useEffect(() => {

    Promise.all([
      getLanguages(), getCategories(), getProductFeatures(), getManufacturers(), getProducts()
    ]).then(()=> {
    }).catch((error)=> {
      console.error(error);
    });

  }, []);


  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  },[location.pathname]);

  const queryString = window.location.pathname;
  const lang = i18next.language || queryString.substring(1,3);

  return (
  <>
    <GlobalStyle />
    <BreakpointProvider>
      <Suspense
        fallback={
          <LoadingOverlay
            active={true}
            spinner={true}
            styles={{
              wrapper: {
                width: "100%",
                height: "100%",
                overflow: "hidden",
              },
            }}
          />
        }>
        <ReactNotifications />
       
        <AuthProvider>
          <Layout>
            <Switch>
              <Route path={`/${lang}`} exact component={Home} />
              <Route path={`/:lang/quemsomos`} component={Quemsomos} />
              <Route path="/:lang/produtos/:id?" component={Produtos} />
              <Route path="/:lang/produto/:slug?" component={Produto} />
              <Route path="/:lang/search/:searchQuery?" component={Search} />
              <Route path="/:lang/filter/:type?/:id?" component={Filter} />
              <Route path="/:lang/recursos" exact component={Recursos} />
              <Route path="/:lang/login" component={Login} />
              <Route path="/:lang/user" component={Conta} />
              <Route path="/:lang/register" component={Register} />
              <Route path="/:lang/recover-password" component={Recover} />
              <Route path="/:lang/privacy" component={Privacy} />
              <Route path="/:lang/news" component={News} />
              <Redirect to={`/${lang}`} />
            </Switch>
          </Layout>
        </AuthProvider>     
      </Suspense>
    </BreakpointProvider>
 </>
  )
};

export default App;

import React from "react";
import { useTranslation } from 'react-i18next';
import Manufacturers from "../Manufacturers/Index";
import { Link } from "react-router-dom";
const projetos = require(`../../img/portugal2020-cor.png`);

const Index = () => {
  const { i18n } = useTranslation('footer');
  return (
    <>
      <div className="marcas">
        <div className="container">
          <Manufacturers />
        </div>
      </div>
      <div className="email-box">
        <h2>{i18n.t('footer:news')}</h2>
        <h3>{i18n.t('footer:contact_us')}</h3>
      </div>
      <div className="contact-box">
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12 col-md-4">
              <h4>{i18n.t('common:address')}</h4>
              Rua da Igreja Velha, 384
              <br /> 4410-160 São Félix da Marinha
              <br /> Vila Nova de Gaia

            </div>
            <div className="col-xs-12 col-md-4">
              <h4>{i18n.t('common:contacts')}</h4> 
              Tel <a href="tel:+351227151060">+351 227 151 060</a><br />
              <small>(Chamada para a rede fixa nacional)</small>
              <br /> Fax +351 227 151 069 <br /> <br />
              <a href="mailto:equilibrio@equilibrio-lda.com">
                equilibrio@equilibrio-lda.com
              </a>
            </div>
            <div className="col-xs-12 col-md-4">
              <h4>{i18n.t('common:location')}</h4>
              <a rel="noopener noreferrer" href="https://www.google.com/maps/place/Equilibrio+-+Produtos+Industriais,+Lda/@41.0243499,-8.6143584,17z/data=!3m1!4b1!4m5!3m4!1s0xd247976a227e773:0xd515942d273e3e25!8m2!3d41.0243459!4d-8.6121697">41.0243499,-8.6143584</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row center-xs">
            <div className="col-md-4">
              <a rel="noopener noreferrer" href="/docs/proj.pdf" target="_blank"><img alt="Projeto 2020" className="img-responsive" src={projetos} /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-links">
        <div className="container">
          <div className="row center-xs">
            <div className="col-md-6">All Rights Reserved</div>
            <div className="col-md-6"><Link to={`/${i18n.language}/privacy`}>{i18n.t('common:privacy_policy')}</Link></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

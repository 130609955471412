import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Auth from "../../services/auth";
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

export default function useAuth() {

  const { i18n } = useTranslation('errors');

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    Auth.isAuthenticated().then((resp) => {
      setAuthenticated(resp);
      setLoading(false);
    }).catch((err)=>{
      console.error(err)
      setAuthenticated(false);
    });
  }, []);

  const notificationsConfig = {
    type: 'danger',                         // 'default', 'success', 'info', 'warning'
    container: 'top-center',                // where to position the notifications
    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    dismiss: {
      duration: 3000
    }
  }

  async function handleLogin(values) {
    return Auth.login(values.username, values.password)
      .then((resp) => {
        setAuthenticated(true);
        setLoading(false);
        return resp;
      })
      .catch((error) => {
    
          store.addNotification({
            message: i18n.t('errors:'+error.message),
            ...notificationsConfig
          })
        

        setLoading(false);
      });
  }

  function handleLogout() {
    Auth.logout().then(() => {
      setAuthenticated(false);
    });
  }

  function handleAuthentication(val) {
    setAuthenticated(val);
  }

  return { authenticated, loading, handleLogin, handleLogout, handleAuthentication };
}

import React, { useEffect, useState } from "react";
import Api from "../../api/api";
import Loading from "../../img/loading.svg";

const imgPlaceholder = require(`../../img/eti.png`);

const Image = ({ width, height, src, alt, type, cat, id, size, title, placeholder }) => {

  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    Api.images(type,size, cat, id).then((res) => {
  
        setImage(res);
        setLoading(false);
      
    }).catch((err) => {
      //console.log(err)
      setLoading(false);
    });
    
  }, [id]);

  if (loading)
  return <div className="text-c"><img src={Loading} alt="loading" width="25" height="25" /></div>;

  if (!loading && !image && placeholder)
  return <img src={imgPlaceholder} width={width} height={height} alt={title} className="img-responsive" />

  if (!loading && !image)
  return null;

  if (!loading && image)
    return (
      <img
        alt={alt}
        title={title}
        src={`data:image/*;base64,${image}`}
        width={width}
        height={height}
        className="img-responsive"
      />
    );
    
};

export default Image;

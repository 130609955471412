import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalState";
import { Context } from "../Auth/Index";
import Tree from "./Tree";
import Dropdown from "./Dropdown";
import Search from "./Search";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";

const search = require(`../../img/icons/loupe.svg`);

//modal da pesquisa
const Button = ({ openModal }) => {
    const btnRef = useRef(null);
    function handleClick() {
        openModal(btnRef);
    }

    return (
        <button className="cursor" ref={btnRef} onClick={handleClick}><img alt="search" width="20px" src={search} /></button>
    );
};

const Menu = () => {

    const { i18n } = useTranslation("header");
    const { authenticated, handleLogout } = useContext(Context);
    const { categories } = useContext(GlobalContext);
    const { modalProps, open } = useModal({ background: '#333' });

    return (

        <ul className="menu">
            <li>
                <Link to={"/"}>{i18n.t("header:home")}</Link>
            </li>
            <li>
                <Link to={`/${i18n.language}/quemsomos`}>{i18n.t("header:aboutus")}</Link>
            </li>
           {/* <li>
                <Link to={`/${i18n.language}/produtos`}>{i18n.t("header:products")}</Link>
                <div className="mega-menu-full">
                    <Tree categories={categories} />
                </div>
    </li> */}
            <li>
                <Link to={`/${i18n.language}/recursos`}>{i18n.t("header:resources")}</Link>
            </li>

            {/*authenticated ? (
                <>
                    <li><Link to={`/${i18n.language}/user`}>{i18n.t("header:account")}</Link></li>
                    <li><a type="link" href="#" onClick={handleLogout}>
                        {i18n.t("header:logout")}
                    </a>
                    </li>
                </>
            ) : (
                    <li>
                        <Link from="home" to={`/${i18n.language}/login`}>
                            {i18n.t("header:login")}
                        </Link>
                    </li>
            )*/}

            {/*
            <li className="responsive">
                <Button openModal={open} />
                <Modal {...modalProps}><Search /></Modal>
            </li>
            */}

            <li>
                <Dropdown />
            </li>
        </ul>

    );
};

export default Menu;

import Api from '../api/api'

class Auth {

    parseJwt(token) {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
    };

    login(username,password) {
        return Api.login(username,password).then((response) => {
            localStorage.setItem(process.env.REACT_APP_TOKEN, response.token);
            //localStorage.setItem(process.env.REACT_APP_USER, JSON.stringify(response.user));
            
            return response;
        })
    }
  
    logout() {
       
            localStorage.removeItem(process.env.REACT_APP_TOKEN);
            //localStorage.removeItem(process.env.REACT_APP_USER);
           
            return Promise.resolve(true);
       
    }

    recover(email) {
        return Api.recover(email).then((response) => {
            return response;
        })
    }

    user(){ 
        
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        return this.parseJwt(token);
    }

    isAuthenticated() {

        
        if(localStorage.getItem(process.env.REACT_APP_TOKEN)){

            return Promise.resolve(true);
           
        } else {
            return Promise.resolve(false);
        }
    }

  }
  
  export default new Auth();
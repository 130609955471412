import axios from 'axios';
import axiosInstance from "./axiosInterceptor";

const url = process.env.REACT_APP_API_URL;

const source = axios.CancelToken.source();

const api = {
  cancel: function(){
    source.cancel()
  },
  languages: function () {    
    return axiosInstance.get(url + "/languages").then((res) => {
      return res.data;
    });
  },
  login: function (email, password) {    
    return axiosInstance.post(url + "/login", {email:email,password:password}).then((res) => {
      return res.data;
    });
  },
  account: function (email) {    
    return axiosInstance.post(url + "/account", {email:email}).then((res) => {
      return res.data;
    });
  },
  updateAccount: function (data) {
    return axiosInstance.post(url + "/updateAccount", data).then((res) => {
      return res.data;
    });
  },
  logout: function () {
    return axiosInstance.post(url + "/logout").then((res) => {
      return res.data;
    });
  },
  recover: function (data) {  
    
    return axiosInstance.post(url + "/recover", data).then((res) => {
      return res.data;
    });
  },
  register: function (data) {
    return axiosInstance.post(url + "/register", data).then((res) => {
      return res.data;
    });
  },
  changePassword: function (email, password) {
    return axiosInstance
      .post(url + "/change_pw", { email: email, password: password })
      .then((res) => {
        return res.data;
      });
  },
  categories: function (id) {

    return axiosInstance.post(url + "/categories", {id: id || ''}).then((res) => {
      return res.data;
    });

  },
  tags: function () {

    /*return axiosInstance.get(url + "/tags").then((res) => {

      let tags = res.data.tags.filter((item)=> parseInt(item.id_lang) === getCurrentLng());  

      const data = {
        tags: tags
      }

      return data;

    });*/
  },
  manufacturers: function () {

    return axiosInstance.get(url + "/manufacturers").then((res) => {
      return res.data;
    });
  },
  product_features: function () {

    return axiosInstance.get(url + "/product_features").then((res) => {
      return res.data;
    });
  },
  search: function (query) {
    
    return axiosInstance.get(url + "/search/" + query).then((res) => {
      return res.data;
    });
  },
  allProducts: function () {

    return axiosInstance.post(url + "/allproducts").then((res) => {
      return res.data;
    });
    
  },
  products: function (id,index,total) {

    return axiosInstance.post(url + "/products", {id: id, index: index, total: total}).then((res) => {
      return res.data;
    });
    
  },
  item: function (id) {
    return axiosInstance.post(url + "/item", {slug:id }).then((res) => {
      return res.data;
    });
  },
  attachments: function (id) {
    return axiosInstance.get(url + "/attachements/list/" + id).then((res) => {
      return res.data;
    });
  },
  download: function (id) {
    return axiosInstance.get(url + "/attachements/download/" + id, {  responseType: 'blob'}).then((res) => {
      return res.data;
    });
  },
  images: function (type,size,cat,id=0) {

    const urlParams = type+'/'+size+'/'+cat+'/'+id;

    return axiosInstance.get(url + "/image/" + urlParams,{ cancelToken: source.token }).then((res) => {
      return res.data;
    });

  },
  product_form: function (data) {
    return axiosInstance.post(url + "/product_contact", data).then((res) => {
      return res.data;
    });
  },
  news: function () {

    return axiosInstance.get(url + "/news").then((res) => {
      return res.data;
    });

  },
};

export default api;

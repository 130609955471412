import React from 'react'
import Header from "../components/Header/Index";
import Footer from "../components/Footer/Index";

const Index = (props) => {
    return (
    <>
        <Header />
        <div className="wrap">
            {props.children}
        </div>
        <Footer />
    </>
    )
};

export default Index;

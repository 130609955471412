import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Search = () => {

  const [query, setQuery] = useState("");

  const { i18n } = useTranslation();

  function passedFunction() {
    window.location.href = "/"+i18n.language+"/search/" + query;
  }

  return (
    <div className="search-modal">
      <form className="form-inline">
        <div className="formGroup">
          <input
            type="text"
            name="query"
            placeholder={i18n.t("common:search")}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            className="btn btn-sm"
            type="button"
            onClick={() => passedFunction()}
          >
            {i18n.t("common:search")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;


import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { GlobalProvider } from "./context/GlobalState";
import './i18n';

import App from './components/App';
//import * as serviceWorker from './serviceWorker';

const base = process.env.REACT_APP_BASE_URL;

render((
  <GlobalProvider>
  <BrowserRouter basename={base}>
    <App/>
  </BrowserRouter>
  </GlobalProvider>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();



